import React from 'react';
import { CircularProgress, Button } from '@mui/material';
import { FaCloudDownloadAlt } from 'react-icons/fa';

function ScriptDownload({
  filesLen,
  name,
  isLoading,
  className,
  btnClick,
  link,
  isDownload = true,
  target = '_blank',
  ...rest
}: any) {
  return (
    <div className={`flex rounded-md pl-4 pr-2 py-2 items-center h-14 ${className || ''}`} {...rest}>
      <div className="w-full break-all">
        <div className="text-xs font-bold text-legacy-blackNear">{name}</div>
      </div>
      {isDownload && <div className="ml-2 w-[2px] h-5 bg-dark-10"></div>}
      {isDownload && (
        <Button disabled={isLoading} onClick={btnClick} className="rounded-md">
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <FaCloudDownloadAlt size={20} className="text-brand-300 cursor-pointer" />
          )}
        </Button>
      )}
    </div>
  );
}

export { ScriptDownload };
